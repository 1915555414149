.CardPortafolio {
    width: 275px;
    margin-left: 40px;
    text-decoration: none;
}

.CardPortafolio .CardPortafolio_img {
    position: relative;
}
.CardPortafolio .CardPortafolio_img img {
    width: 100%;
    height: 175px;
    border-radius: 15px 15px 0 0;
}

.CardPortafolio .CardPortafolio_linea {
    height: 40px;
    width: 100%;
    background: linear-gradient(to right bottom, transparent 48%, black 50%);
    border: none;
    z-index: 99;
    position: absolute;
    bottom: 0;
    /*transform: skewY(-11deg);*/
}

.CardPortafolio_contenido {
    background: black;
    padding: 10px 15px 25px;
    border-radius: 0 0 15px 15px;
    color: white;
}

.CardPortafolio_contenido__title {
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}

.CardPortafolio_contenido__text {
    margin-top: 20px !important;
    text-align: justify;
    margin: 0;
}