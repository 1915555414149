@media(max-width: 1050px) {
    .neonText__1 {
        font-size: 4rem;
    }
    .neonText__2 {
        font-size: 2.8rem;
    }
    
    .container section {
        min-height: 100vh;
        height: max-content;
    }
    

    .container, #Portafolio {        
        overflow: hidden;
    }

    #Portafolio {
        padding: 100px 0;
    }

    #Portafolio .contenedor__informacion h2, #Portafolio .contenedor__informacion h3 {
        padding: 0 30px;
        position: relative;
        z-index: 2;
    }

    #Portafolio .contenedor__informacion .list_proyects {
        flex-wrap: wrap;
    }

    #Portafolio .contenedor__informacion .list_proyects .CardPortafolio {
        margin-left: 20px;
    }



    #Skills .contenedor__informacion .list_skills {
        width: 100%;
    }
    #Skills .contenedor__informacion .list_skills .list_skills__wrapper {
        padding: 0;
    }
}

@media(min-width: 500px) and (max-width: 685px){
    .neonText__1 {
        font-size: 3rem;
    }
    .neonText__2 {
        font-size: 2rem;
    }

    #Portafolio {
        padding: 120px 0;
    }

    #Portafolio .contenedor__informacion .more_proyects {
        right: 5px;
    }

    #Skills .contenedor__informacion .list_skills .list_skills__wrapper .list_skills__container {
        min-width: 180px;
    }
}

@media(max-width: 500px) {
    .App-header {
        flex-direction: column;
        display: flex;
        width: 100%;
    }

    .App-header .Links {
        position: initial;
        left: 0;
        transform: none;
    }

    .neonText__1 {
        font-size: 2rem;
    }
    .neonText__2 {
        font-size: 1.8rem;
    }

    #Portafolio .contenedor__informacion .list_proyects .CardPortafolio {
        margin-left: 0px;
        margin-bottom: 20px;
    }

    #Portafolio .contenedor__informacion .more_proyects {
        width: 90%;
        margin: 0 10%;
        transform: translateX(6%);
    }


    #Skills .contenedor__informacion .list_skills {
        height: max-content;
    }

    #Skills .contenedor__informacion .list_skills .list_skills__wrapper .list_skills__container {
        min-width: 180px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    #Skills .contenedor__informacion .list_skills .list_skills__wrapper .list_skills__container .list_skills__item {
        width: 45%;
        height: 100px;
    }
}