.list_skills__wrapper {
  width: 100%;
  height: 100%;
  background: transparent;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list_skills__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 1fr));
  grid-gap: 25px;
  padding: 25px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 10px 10px -5px rgba(0, 0, 0, 0.05);
  will-change: width, height;
}

.add_box_shadow {
  box-shadow:
    0 0 42px white,
    0 0 82px white,
    0 0 92px white;
    transition: all 2.5s ease-in-out 0s;
}

.remove_box_shadow {
  box-shadow: none;
}

.list_skills__description {
  width: 175px;
  backdrop-filter: blur(5px);
  text-align: center;
  border-radius: 12px;
}

.list_skills__description h2 {
  color: black;
}
  
.list_skills__item {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  will-change: transform, opacity;
  color: rgb(22, 21, 21);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
  
.list_skills__item h4, .list_skills__item h5 {
  margin: 0;
  padding: 0;
  text-align: center;
}

.list_skills__item h5 {
  color: rgb(49, 83, 128);
}