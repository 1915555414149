*, html {
    scroll-behavior: smooth !important;
}

.container {
    background-color: rgb(0, 1, 20);
    height: 100%;
}

body::-webkit-scrollbar {
    width: 12px; 
} 
  
body::-webkit-scrollbar-track {
background: rgb(58, 58, 58);
}

body::-webkit-scrollbar-thumb {
background-color: #bc13fe;
border-radius: 20px;
}

.container section {
    height: 100vh;
    display: flex;
    flex-direction: center;
    align-items: center;
    justify-content: center;
}

.App-header {
    position: fixed;
    width: calc(100% - 75px);
    background-color: rgba(32, 32, 32, 0.397);
    z-index: 5;
    top: 5%;
    display: flex;
    padding: 20px 30px;
    backdrop-filter: blur(5px);
}

.App-header .logo {
    color: white;
    font-size: 25px;
}

.App-header .Links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.App-header .Links a {
    font-size: 18px;
    color: rgb(118, 119, 130);
    transition: all 0.5s ease-in-out 0s;
    text-decoration: none;
    margin-right: 15px;
}

.App-header .Links a:hover {
    color: white;
    transition: all 0.5s ease-in-out 0s;
    font-size: 20px;
}

.neonText__1 {
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #bc13fe,
        0 0 82px #bc13fe,
        0 0 92px #bc13fe,
        0 0 102px #bc13fe,
        0 0 151px #bc13fe;
}

.neonText__2 {
    color: #fff;
    font-size: 3.2rem;
    text-align: center;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #fe13f2,
        0 0 82px #fe13f2,
        0 0 92px #fe13f2,
        0 0 102px #fe13f2,
        0 0 151px #fe13f2;
}

h1 {
    font-size: 6.2rem;
    border: 0.2rem solid #fff;
    border-radius: 2rem;
    padding: 0.4em;
    box-shadow: 0 0 .2rem #fff,
                0 0 .2rem #fff,
                0 0 2rem #bc13fe,
                0 0 0.8rem #bc13fe,
                0 0 2.8rem #bc13fe,
                inset 0 0 1.3rem #bc13fe; 
}

/*--------------*/

.contenedor__informacion h2 {
    color: white;
    margin: 0;
}

.contenedor__informacion h3 {
    color: gray;
    margin: 0;
    margin-bottom: 1rem;
}

.contenedor__informacion {
    color: white;
    position: relative;
}

.contenedor__informacion .list_proyects {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 2;
}


/*------------*/

.icons-1 { 
    width: 50px;
    height: 50px;
    position: absolute;
    border: 10px solid white;
    border-radius: 50px;
    z-index: 0;
    box-shadow:
        0 0 42px white,
        0 0 82px white,
        0 0 92px white;
}

.icons-2 {
    width: 50px;
    height: 50px;
    position: absolute;
    border: 10px solid #bc13fe;
    z-index: 0;
    box-shadow:
        0 0 42px #bc13fe,
        0 0 82px #bc13fe,
        0 0 92px #bc13fe;
}


/*                        */

.list_skills {
    width: 900px;
    height: 500px;
}

.more_proyects {
    background-color: rgb(49, 40, 131);
    width: 150px;
    height: 40px;
    display: flex;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: -50px;
    cursor: pointer;
    text-decoration: none;
    color: white;
}

.more_proyects p {
    align-self: center;
    font-weight: bold;
}

/*.more_proyects__linea {
    width: 4px;
    height: 30px;
    z-index: 1;
    transform: translateY(-50%);
    animation: rotateMore 1.5s linear infinite; 
}

@keyframes rotateMore{
    to{ transform: rotate(360deg); }
  }*/

footer {
    width: 100%;
    min-height: 120px;
    background: rgb(49, 40, 131);
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    padding: 50px 0;
}
footer div h4 {
    text-align: center;
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 9px;
    color: white;
    padding-right: 20px;
}

footer div img {
    border-radius: 50%;
    margin-right: 20px;
}

footer div p {
    text-align: center;
    padding-right: 20px;
    font-weight: 800;
    color: rgba(255, 255, 255, 0.432);
}

#Home {
    position: relative;
}

#iconCursor {
    transform: translateY(-50%);
    animation: rotateMore 1.2s linear infinite;
    filter: brightness(0) invert(1);
    position: absolute;
    bottom: 0;
}

@keyframes rotateMore{
    to{ transform: rotate(360deg); }
}